const targetState = [
  {"countrycode":"AR","state":["buenosaires","buenosairesf.d.","chubut","cordoba","entrerios","mendoza","neuquen","rionegro","santafe","tucuman","sanjuan","sanluis","chaco","misiones","salta","santacruz","tierradelfuego","catamarca","lapampa","larioja","corrientes","jujuy","formosa"]},
  {"countrycode":"BO","state":["cochabamba","lapaz","santacruz","elbeni","tarija","chuquisaca","pando","oruro","potosí"]},
  {"countrycode":"BR","state":["bahia","ceará","espíritosanto","goiás","maranhão","matogrosso","matogrossodosul","minasgerais","paraná","pará","piauí","riodejaneiro","riograndedonorte","riograndedosul","santacatarina","sãopaulo","alagoas","federaldistrict","paraíba","pernambuco","rondônia","sergipe","amazonas","tocantins","acre","amapá","roraima"]},
  {"countrycode":"CL","state":["santiagometropolitan","valparaíso","biobío","mauleregion","loslagosregion","tarapacá","ñuble","antofagasta","coquimboregion","araucanía","o'higginsregion","regionofmagallanes"]},
  {"countrycode":"CR","state":["alajuela","sanjosé","guanacaste","heredia","cartago","limón","puntarenas"]},
  {"countrycode":"EC","state":["guayas","pichincha","eloro","azuay","manabí","loja","carchi","chimborazo","cotopaxi","esmeraldas","losríos","orellana","santodomingodelostsáchilas","tungurahua","pastaza","imbabura","santaelena","sucumbios","bolívar","zamorachinchipe","cañar","moronasantiago"]},
  {"countrycode":"GT","state":["guatemala","quetzaltenango"]},
  {"countrycode":"HN","state":["cortés","franciscomorazán","bayislands","choluteca","colón","olancho","yoro","lempira","comayagua","elparaíso","copán","atlántida","santabárbara"]},
  {"countrycode":"JM","state":["kingston","saintcatherine","st.james"]},
  {"countrycode":"MX","state":["aguascalientes","bajacalifornia","campeche","chiapas","chihuahua","coahuila","colima","guanajuato","guerrero","hidalgo","jalisco","mexicostate","michoacán","morelos","méxico","nayarit","nuevoleón","oaxaca","puebla","querétaro","quintanaroo","sanluispotosí","sinaloa","sonora","tabasco","tamaulipas","tlaxcala","veracruz","yucatán","zacatecas","bajacaliforniasur","durango"]},
  {"countrycode":"NI","state":["managua","estelí","león","masaya","chinandega","southcaribbeancoast"]},
  {"countrycode":"PA","state":["panamá","colón","panamáoeste","chiriquí","coclé"]},
  {"countrycode":"SR","state":["paramaribo"]},
  {"countrycode":"SV","state":["sansalvador","sanmiguel","santaana","usulután","lalibertad","sonsonate","chalatenango"]},
  {"countrycode":"UY","state":["maldonado","montevideo","rivera","cerrolargo","florida","artigas","salto"]},
  {"countrycode":"VE","state":["distritofederal","zulia","carabobo","vargas","lara","miranda","mérida","táchira","aragua","anzoátegui","bolívar","falcón"]},
  {"countrycode":"CA","state":["alberta","ontario","newfoundlandandlabrador","britishcolumbia","quebec","manitoba","novascotia","newbrunswick","saskatchewan","princeedwardisland"]},
  {"countrycode":"CO","state":["bogotad.c.","antioquia","santander","bolívar","atlántico","boyacá","caldas","casanare","cauca","cesar","cundinamarca","huila","magdalena","meta","nortedesantander","putumayo","risaralda","tolima","valledelcauca","nariño","córdoba"]},
  {"countrycode":"DO","state":["nacional","laromana","santiago","santodomingo","lavega","barahona","monseñornouel","puertoplata","sanpedrodemacorís","laaltagracia"]},
  {"countrycode":"BA","state":["federationofb&h","srpska"]},
  {"countrycode":"BE","state":["brusselscapital","flanders","wallonia"]},
  {"countrycode":"CI","state":["abidjan"]},
  {"countrycode":"DE","state":["badenwurttemberg","bavaria","hesse","lowersaxony","northrhinewestphalia","berlin","rheinlandpfalz","schleswigholstein","hamburg","saxony","saxonyanhalt","bremen","saarland","brandenburg","mecklenburgvorpommern"]},
  {"countrycode":"ES","state":["andalusia","castillelamancha","catalonia","madrid","murcia","valencia","basquecountry","canaryislands","castilleandleón","extremadura","navarre","asturias","galicia","aragon","balearicislands","cantabria","larioja"]},
  {"countrycode":"FR","state":["auvergnerhônealpes","bourgognefranchecomté","grandest","hautsdefrance","normandy","nouvelleaquitaine","occitanie","provencealpescôted'azur","îledefrance","brittany","centre","paysdelaloire","corsica"]},
  {"countrycode":"GB","state":["england","scotland","wales","northernireland"]},
  {"countrycode":"GR","state":["attica","centralmacedonia","thessaly","westgreece"]},
  {"countrycode":"IE","state":["leinster","munster","connacht"]},
  {"countrycode":"IT","state":["campania","lazio","lombardy","sicily","apulia","veneto","piedmont","tuscany","liguria","emiliaromagna","themarches","calabria","abruzzo","sardinia","umbria","trentinoaltoadige"]},
  {"countrycode":"KE","state":["nairobiarea","mombasa"]},
  {"countrycode":"PT","state":["braga","lisbon","porto","aveiro","districtofsetúbal","faro","leiria","azores","viseu","coimbra"]},
  {"countrycode":"RU","state":["krasnodarskiy","leningradskayaoblast'","moscow","moscowoblast","rostov","st.petersburg","stavropol’kray","tyumen’oblast","voronezj","altaikrai","bashkortostanrepublic","belgorodoblast","chechnya","chelyabinsk","dagestan","irkutskoblast","ivanovo","kabardinobalkariyarepublic","kaliningrad","kaluga","kemerovooblast","khabarovsk","khantymansia","krasnoyarskiy","lipetskoblast","nizhnynovgorodoblast","novosibirskoblast","omsk","perm","primorskiy(maritime)kray","sakha","samaraoblast","saratovskayaoblast","sverdlovsk","tatarstanrepublic","tomskoblast","tver’oblast","udmurtiyarepublic","volgogradoblast","arkhangelskaya","astrakhan","bryanskoblast","kursk","orenburgoblast","tula","vladimir","yaroslavloblast","buryatiyarepublic","ulyanovsk","transbaikalterritory","khakasiyarepublic","amuroblast","kurganoblast","murmansk","smolenskayaoblast’","pskovoblast","kamchatka","kirov","sakhalinoblast","chuvashia","ryazanoblast","penza","northossetia","komi","kostromaoblast","vologda","tambov","novgorodoblast","yamalonenets","karelia"]},
  {"countrycode":"TN","state":["tūnis","gafsa","ariana","sūsah","almunastīr","şafāqis","banzart","nābul","bin‘arūs"]},
  {"countrycode":"UA","state":["dnipropetrovskoblast","kyivstate","lvivoblast","crimea","khersonoblast","kiev","odessa","ternopiloblast","zhytomyr","donetskoblast","transcarpathia","vinnytsyaoblast","kirovohradoblast","chernivtsioblast","ivanofrankivskoblast","kharkiv","khmelnytskyioblast","luhansk","poltavaoblast","rivne","sumy","zaporizhzhyaoblast","cherkasyoblast","mykolayivoblast","volyn","sevastopolstate"]},
  {"countrycode":"US","state":["california","texas","colorado","florida","illinois","newjersey","newyork","ohio","pennsylvania","virginia","georgia","indiana","massachusetts","nevada","northcarolina","washington","arizona","southcarolina","maryland","michigan","tennessee","oregon","alabama","arkansas","connecticut","delaware","hawaii","idaho","iowa","kansas","kentucky","louisiana","minnesota","mississippi","missouri","nebraska","newmexico","oklahoma","rhodeisland","utah","wisconsin","newhampshire","northdakota","westvirginia"]},
  {"countrycode":"ZA","state":["gauteng","westerncape","easterncape","kwazulunatal","limpopo","northwest","mpumalanga","orangefreestate"]},
  {"countrycode":"AL","state":["tirana","vlorë","durrës","korçë","shkodër","elbasan"]},
  {"countrycode":"AZ","state":["baki"]},
  {"countrycode":"BG","state":["sofiacapital","pazardzhik","pernik","sofia","varna","plovdiv","burgas","starazagora"]},
  {"countrycode":"BY","state":["minsk","brest","minskstate","grodnenskaya","mogilev","gomeloblast","vitebsk"]},
  {"countrycode":"CZ","state":["hlavníměstopraha","moravskoslezský","plzeňskýkraj","southmoravian","centralbohemia","libereckýkraj","olomoucký"]},
  {"countrycode":"EG","state":["cairo","giza","alexandria","beheira","dakahlia","damietta","faiyum","gharbia","monufia","portsaid","qalyubia","sharqia","sohag","asyut","ismailia","redsea","benisuweif"]},
  {"countrycode":"GE","state":["abkhazia","imereti","t'bilisi","achara"]},
  {"countrycode":"NL","state":["northbrabant","northholland","southholland","limburg","gelderland","overijssel","utrecht"]},
  {"countrycode":"RS","state":["centralserbia","vojvodina"]},
  {"countrycode":"SE","state":["stockholm","västragötaland","skåne"]},
  {"countrycode":"UZ","state":["bukhara","tashkent","samarqand","toshkent","namangan","xorazm","karakalpakstan","navoiy"]},
  {"countrycode":"LT","state":["vilnius","kaunas"]},
  {"countrycode":"NG","state":["lagos"]},
  {"countrycode":"LV","state":["riga","liepāja"]},
  {"countrycode":"DZ","state":["algiers","oran","blida","constantine","sétif","tlemcen","batna"]},
  {"countrycode":"GH","state":["greateraccra"]},
  {"countrycode":"HR","state":["stateofzagreb","osječkobaranjska"]},
  {"countrycode":"HU","state":["borsodabaújzemplén","budapest","pest"]},
  {"countrycode":"KG","state":["bishkek","osh"]},
  {"countrycode":"KZ","state":["almaty","astana","karaganda","qostanay","atyraū","eastkazakhstan","pavlodarregion","aqmola"]},
  {"countrycode":"MA","state":["casablancasettat","rabatsalékénitra","fèsmeknès","marrakeshsafi","soussmassa","tangertetouanalhoceima","oriental"]},
  {"countrycode":"MD","state":["chișinăumunicipality","transnistria","bălţi"]},
  {"countrycode":"ME","state":["podgorica"]},
  {"countrycode":"MK","state":["gradskopje","gostivar","ohrid","strumica","tetovo","kumanovo","bitola"]},
  {"countrycode":"MU","state":["plaineswilhems","portlouis"]},
  {"countrycode":"PL","state":["mazovia","silesia","lesserpoland","greaterpoland"]},
  {"countrycode":"RO","state":["bucurești"]},
  {"countrycode":"SN","state":["dakar"]},
  {"countrycode":"XK","state":["pristina","mitrovica","prizren","pec"]},
  {"countrycode":"AO","state":["luanda"]},
  {"countrycode":"LY","state":["tripoli"]},
  {"countrycode":"SI","state":["ljubljana"]},
  {"countrycode":"AM","state":["yerevan"]},
  {"countrycode":"SO","state":["woqooyigalbeed"]},
  {"countrycode":"RE","state":["réunion"]},
  {"countrycode":"PY","state":["altoparaná","asunción","canindeyú","amambay","central","misiones","ñeembucú","itapúa"]},
  {"countrycode":"PE","state":["lima","arequipa","tacna","lalibertad","limaregion","lambayeque","ica"]},
  {"countrycode":"HK","state":["centralandwestern","north","saikung","shamshuipo","shatin","southern","taipo","tsuenwan","tuenmun","yuenlong"]},
  {"countrycode":"GY","state":["demeraramahaica"]},
  {"countrycode":"MY","state":["kualalumpur","selangor","penang","johor","perak","kedah","kelantan","melaka","negerisembilan","pahang","sabah","sarawak","terengganu","perlis"]},
  {"countrycode":"PR","state":["sanjuan","bayamón","aguadilla","carolina","ponce","arecibo","vegabaja"]},
  {"countrycode":"ID","state":["banten","centraljava","eastjava","jakarta","westjava","yogyakarta","aceh","bali","centralkalimantan","centralsulawesi","eastkalimantan","eastnusatenggara","jambi","lampung","maluku","northsulawesi","northsumatra","papua","riau","southkalimantan","southsulawesi","southsumatra","westkalimantan","westnusatenggara","westsumatra","gorontalo","westpapua","bangka–belitungislands","bengkulu","northmaluku","southeastsulawesi","northkalimantan"]},
  {"countrycode":"PH","state":["calabarzon","metromanila","cagayanvalley","centralluzon","centralvisayas","davao","easternvisayas","ilocos","northernmindanao","westernvisayas","bicol","cordillera","mimaropa","soccsksargen","zamboangapeninsula","caraga"]},
  {"countrycode":"VN","state":["hanoi","hochiminh","bìnhdương","bìnhthuận","cantho","danang","haiphong","hưngyên","hảidương","quảngninh","thanhhóa","đồngnai","tháinguyên","namđịnh","nghệan","phúthọ","bìnhđịnh","lâmđồng","ninhbình","longan","angiang","bìnhphước","khánhhòa","quảngnam","bắcgiang","thừathiênhuế","tràvinh","đồngtháp","bắcninh","tháibình","tiềngiang","vĩnhlong","đắklắk","kiếngiang","sơnla","càmau","sóctrăng","hànam","bàrịavũngtàu","gialai"]},
  {"countrycode":"TT","state":["chaguanas","portofspain"]},
  {"countrycode":"BB","state":["saintmichael"]},
  {"countrycode":"BD","state":["dhaka","rajshahidivision","khulna","mymensinghdivision","chittagong","sylhet","rangpurdivision"]},
  {"countrycode":"CU","state":["havana"]},
  {"countrycode":"IN","state":["delhi","karnataka","kerala","maharashtra","punjab","tamilnadu","westbengal","gujarat","telangana","andhrapradesh","chandigarh","haryana","madhyapradesh","rajasthan","uttarpradesh","mizoram","bihar","uttarakhand","chhattisgarh","jammuandkashmir","jharkhand","himachalpradesh","odisha","assam","puducherry"]},
  {"countrycode":"IR","state":["kordestān","tehran","razavikhorasan","isfahan","khuzestan","eastazerbaijan","fars","golestan"]},
  {"countrycode":"JO","state":["amman","irbid","aqaba"]},
  {"countrycode":"KR","state":["chungcheongnamdo","gyeonggido","incheon","jeollabukdo","seoul","ulsan","busan","gyeongsangnamdo","daejeon","jeollanamdo","daegu","gwangju","gangwondo","northchungcheong"]},
  {"countrycode":"MM","state":["mandalay","shan","yangon","sagain","kayin","naypyitaw"]},
  {"countrycode":"MN","state":["ulaanbaatar"]},
  {"countrycode":"NP","state":["bagmatiprovince","lumbiniprovince","province1","province2","province4","sudurpashchimpradesh"]},
  {"countrycode":"PK","state":["punjab","sindh","khyberpakhtunkhwa","balochistan","islamabad"]},
  {"countrycode":"SA","state":["easternprovince","meccaregion","riyadhregion","'asirregion","medinaregion","alqassimregion","jazanregion"]},
  {"countrycode":"TR","state":["istanbul","ankara","bursa","adana","izmir","antalya","mersin","aydın","balıkesir","canakkale","denizli","diyarbakır","gaziantep","hatay","kayseri","kocaeli","konya","manisa","muğla","sakarya","samsun","tekirdağ","trabzon","zonguldak","eskişehir","kahramanmaraş","kütahya","kırklareli","şanlıurfa"]},
  {"countrycode":"BJ","state":["littoral"]},
  {"countrycode":"FI","state":["uusimaa"]},
  {"countrycode":"SD","state":["khartoum"]},
  {"countrycode":"TZ","state":["daressalaam"]},
  {"countrycode":"UG","state":["centralregion"]},
  {"countrycode":"CG","state":["pointenoire","brazzaville"]},
  {"countrycode":"BW","state":["gaborone"]},
  {"countrycode":"IQ","state":["baghdad","duhok","arbīl","basra","maysan","kirkuk","nineveh","sulaymaniyah","annajaf","karbalāʼ","bābil","salahaddin","wāsiţ","diyālá","alanbar"]},
  {"countrycode":"SK","state":["bratislavskýkraj","žilinskýkraj"]},
  {"countrycode":"AT","state":["vienna","upperaustria","styria"]},
  {"countrycode":"EE","state":["harjumaa","idavirumaa"]},
  {"countrycode":"GA","state":["estuaire"]},
  {"countrycode":"CH","state":["zurich","bern","geneva","vaud"]},
  {"countrycode":"NA","state":["khomas"]},
  {"countrycode":"AE","state":["abudhabi","dubai","sharjah","raʼsalkhaymah","ajman","fujairah"]},
  {"countrycode":"AU","state":["newsouthwales","victoria","westernaustralia","queensland","southaustralia"]},
  {"countrycode":"BH","state":["manama","northern","southerngovernorate","muharraq"]},
  {"countrycode":"CN","state":["chongqing","shanghai","beijing","guangdong","jiangsu","fujian","shandong","shaanxi","zhejiang","guangxi","hubei","sichuan"]},
  {"countrycode":"TW","state":["taipei","taiwan","takao"]},
  {"countrycode":"GD","state":["saintgeorge"]},
  {"countrycode":"JP","state":["tokyo","ōsaka","aichi","shizuoka","kanagawa"]},
  {"countrycode":"KH","state":["phnompenh","preahsihanouk","banteaymeanchey","svayrieng"]},
  {"countrycode":"LK","state":["western","uva"]},
  {"countrycode":"PS","state":["westbank","gazastrip"]},
  {"countrycode":"BS","state":["newprovidence"]},
  {"countrycode":"DK","state":["capitalregion","centraljutland"]},
  {"countrycode":"LU","state":["luxembourg"]},
  {"countrycode":"ML","state":["bamako"]},
  {"countrycode":"OM","state":["muscat","albatinahnorth"]},
  {"countrycode":"TG","state":["maritime"]},
  {"countrycode":"KW","state":["hawalli","alaḩmadī"]},
  {"countrycode":"MT","state":["birkirkara","saintpaul’sbay"]},
  {"countrycode":"NO","state":["oslo"]},
  {"countrycode":"TH","state":["bangkok","chonburi","udonthani","songkhla","nakhonpathom","samutprakan","phuket","pathumthani","chiangmai","chiangrai","prachinburi","rayong","khonkaen","nakhonratchasima","phranakhonsiayutthaya","prachuapkhirikhan","nongkhai","uttaradit","suratthani","nakhonsithammarat","buriram","ubonratchathani","chanthaburi","nakhonsawan","nonthaburi","tak"]},
  {"countrycode":"IL","state":["centraldistrict","telaviv","jerusalem","haifa","southerndistrict","northerndistrict"]},
  {"countrycode":"QA","state":["baladīyataddawḩah"]},
  {"countrycode":"BN","state":["bruneimuaradistrict"]},
  {"countrycode":"NZ","state":["auckland","wellington","canterbury","waikato"]},
  {"countrycode": "GM","state": [ "banjul" ]}
]
module.exports = targetState